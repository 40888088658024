import t from "@common/src/i18n/t";
/**金额校验:小数点后2位内 0.00格式，任意正整数，小数不超过2位,不能为0
 * @param {number | string} num
 */
export function verifyMoney(num) {
  let patt = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
  if (patt.test(num)) {
    return true;
  } else {
    return false;
  }
}

// 密码规则
/**
 * @param {number | string} password
 * @return {object: Error}
 */
export function passwordRules(password) {
  const regex = /^\w{6,18}$/;
  if (!password) {
    return {
      success: false,
      msg: t("login.password-empty-tips")
    };
  }
  if (password.length < 6 || password.length > 18) {
    return {
      success: false,
      msg: "密码长度为6-18位"
    };
  }
  if (!regex.test(password)) {
    return {
      success: false,
      msg: "密码只能由数字字母下划线组合"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

// 中国手机号规则
/**
 * @param {number | string} mobile
 * @return {object: Error}
 */
export function mobileRules(mobile) {
  const regex = /^1[0-9]{10}$/;
  if (!mobile) {
    return {
      success: false,
      msg: "请输入手机号"
    };
  }
  if (mobile.length !== 11) {
    return {
      success: false,
      msg: "手机号长度为11位"
    };
  }
  if (!regex.test(mobile)) {
    return {
      success: false,
      msg: "中国大陆手机号为11位数字组成"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

// 邮箱规则
/**
 * @param {string} mail
 * @return {object: Error}
 */
export function mailRules(mail) {
  const regex = /^([a-zA-Z\d])(\w|-|.)*@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
  if (!mail) {
    return {
      success: false,
      msg: "邮箱不能为空"
    };
  }
  if (!regex.test(mail)) {
    return {
      success: false,
      msg: "邮箱格式不正确"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

// 价格规则
/**
 * @param {number | string} price
 * @return {object: Error}
 */
export function priceRules(price) {
  const regex = /^[0-9]+(.[0-9]+)?$/;
  if (!regex.test(price)) {
    return {
      success: false,
      msg: "请输入数字"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

/**
 * 一般编号规则：数字字母下划线组成
 * @param {number | string} generalNumber
 * @return {object: Error}
 */
export function generalNumberRules(generalNumber) {
  const regex = /^\w+$/;
  if (!regex.test(generalNumber)) {
    return {
      success: false,
      msg: "请输入字母数字下划线"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

// 身份证规则
/**
 * @param {string} id
 * @return {object: Error}
 */
export function checkIdentityId(id) {
  const regex = /^([0-9]|[a-z]|[A-Z])*$/;
  if (!regex.test(id)) {
    return {
      success: false,
      msg: "身份证号由数字和字母组成"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

// 车牌号
/**
 * @param {string} code
 * @return {object: Error}
 */
export function checkPlateCode(code) {
  const regex = /^([0-9]|[a-z]|[A-Z])*$/;
  if (code.length <= 1) {
    return {
      success: false,
      msg: "请输入车牌号"
    };
  }
  const plateNum = code.substr(1);
  if (!regex.test(plateNum)) {
    return {
      success: false,
      msg: "车牌号码部分由数字和字母组成"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

// 密码规则配合el-form使用
/**
 * @param {number | string} password
 * @param {Element:callback} callback
 */
export function passwordRulesEl(password, callback) {
  let match = passwordRules(password);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}

// 手机规则配合el-form使用
/**
 * @param {number | string} mobile
 * @param {Element:callback} callback
 */
export function mobileRulesEl(mobile, callback) {
  let match = mobileRules(mobile);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}

// 邮箱规则配合el-form使用
/**
 * @param {number | string} mail
 * @param {Element:callback} callback
 */
export function mailRulesEl(mail, callback) {
  let match = mailRules(mail);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}

// 价格配合el-form使用
/**
 * @param {number | string} price
 * @param {Element:callback} callback
 */
export function priceRulesEl(price, callback) {
  let match = priceRules(price);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}

// 身份证号校验配合el-form使用
/**
 * @param {number | string} price
 * @param {Element:callback} callback
 */
export function identityIdRulesEl(id, callback) {
  let match = checkIdentityId(id);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}

// 身份证号校验配合el-form使用
/**
 * @param {number | string} plateCode
 * @param {Element:callback} callback
 */
export function plateCodeRuleEl(plateCode, callback) {
  let match = checkPlateCode(plateCode);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}

/**
 * 零配件数量规则
 * 大于等于0 可以为小数，保留1位小数点
 * @param {number | string} quantity
 * @return {object: Error}
 */
export function partsQuantityRules(quantity) {
  const regex = /^[0-9]+(.[0-9]{1})?$/;
  quantity = String(quantity);
  if (!regex.test(quantity)) {
    return {
      success: false,
      msg: "零配件数量为数值且最多一位小数"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

/**
 * 验证正整数
 * @param {number | string} num
 * @return {boolean: result}
 */
export function isPositiveInteger(num) {
  const regex = /^\d+$/;
  if (regex.test(num) && num > 0) {
    return true;
  }
  return false;
}

/**
 * 验证正整数和0
 * @param {number | string} num
 * @return {boolean: result}
 */
export function isPositiveIntegerZero(num) {
  const regex = /^\d+$/;
  if (regex.test(num) && num >= 0) {
    return true;
  }
  return false;
}

/**
 * 验证金额 0.00格式，任意正整数，小数不超过2位
 * @param {number} number
 * @return {boolean: result}
 */

export function isMoney(number) {
  const isNum = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
  if (isNum.test(number)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 用户名规则
 * @param { string} username
 * @return {object: Error}
 */
export function usernameRules(price) {
  const regex = /^([0-9]|[a-z]|[A-Z]|_|.){6,18}$/;
  price = String(price);
  if (!regex.test(price)) {
    return {
      success: false,
      msg: "用户名由6-18位下划线数字字母组成"
    };
  }
  return {
    success: true,
    msg: ""
  };
}

/**用户名
 * @param {number | string} username
 * @param {Element:callback} callback
 */
export function usernameRuleEl(username, callback) {
  let match = usernameRules(username);
  if (match.success) {
    callback();
  } else {
    callback(new Error(match.msg));
  }
}



/**
 * 返回去除逗号的字符串
 * @param{string} num
 * @return {string}
 */

export function replaceComma(string) {
  return string.replace(/,/g, "");
}

