
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import BtnCheckGroup from "@/components/scope/btn-check-group.vue";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import dayjs from "dayjs";
import { priceRulesEl, partsQuantityRules } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";

const parts = namespace("parts");
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
const inventory = namespace("inventory");
const serviceItem = namespace("service-item");

@Component({
  components: { FileUpload, BtnCheckGroup, GeneralVehicleSelect }
})
export default class ServiceItemEdit extends Vue {
  // @app.Mutation setPBPX;
  @serviceItem.Action createServiceItem;
  @serviceItem.Action updateServiceItem;
  @serviceItem.Action getopserviceitem;
  @serviceItem.Action getServiceDetail;
  @serviceItem.Action queryTwoLevelClassList;
  @parts.Action getPartsList;
  @parts.Action getPartsDetail;
  @user.State userData;
  @base.Action getDictionaryList;
  @inventory.Action saveInWarehouse;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  dayjs = dayjs;
  splitThousands = splitThousands;

  orderFormData: any = {
    serviceType: "1", // 工时or套餐
    serviceCategoryCode: "", // 二级分类
    serviceName: "", // 项目名称
    vehicleSeriesNames: [], // 适用车系
    workingHours: "", // 工时
    serviceFee: "", // 工时费
    status: "1", // 是否启用
    inboundType: "",
    servicePartsList: [],
    costAmount: "0",
    storeTypes: [],//门店类型
    description: "",
    referencePrice: "",
    imgUrl: "",
    carWashMode: null,
    detailImgUrl: "",
    runMode: 1,
    vacuumingTime: 0
  };
  defaultBannerList: any = [];

  defaultDetailList: any = [];
  carWashModes: any = [
    // {
    //   label: "普洗模式",
    //   value: 1
    // },
    {
      label: "精洗模式",
      value: 2
    },
    {
      label: "DIY(精洗)模式",
      value: 3
    },
    {
      label: "精洗(吸尘)模式",
      value: 4
    },
    {
      label: "DIY(吸尘)模式",
      value: 5
    },
  ];

  runModes: any = [
    {
      label: "单码模式",
      value: 1
    },
    {
      label: "双码模式",
      value: 2
    },
  ];
  get isSystemSet() {
    //   后面看
    // return this.orderFormData.source === "1";
    return false;
  }
  typeOptionsData: any = [
    {
      label: t("setting.working-hours-project"),
      value: "1"
    },
    {
      label: t("setting.plan-project"),
      value: "2"
    },
    {
      label: t("setting.car-wash-project"),
      value: "3"
    }
  ];
  get breadData() {
    return [
      { name: t("finance.service-items"), path: "/management/service-list" },
      {
        name: this.isAdd ? t("setting.add-service-items") : t("setting.editing-service-items")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "ManagementServiceAdd";
  }
  orderFormDataRules = {
    serviceCategoryCode: [{ required: true, message: t("setting.select-secondary-category"), trigger: "change" }],
    carWashMode: [{ required: true, message: t("setting.select-car-wash-mode"), trigger: "change" }],
    serviceName: [{ required: true, message: t("setting.enter-project-name"), trigger: "blur" }],
    description: [{ required: true, message: t("setting.enter-description"), trigger: "blur" }],
    serviceFee: [
      { required: true, message: t("setting.enter-hourly-rate-package-rate"), trigger: "blur" },
      {
        validator: this.checkPrice,
        trigger: "blur"
      }
    ],
    status: [{ required: true, message: t("setting.select-whether-enable"), trigger: "blur" }],
    storeTypes: [{ required: true, message: "请选择", trigger: "blur" }],
    imgUrl: [{ required: true, message: t("setting.please-enter-service-item-img"), trigger: "change" }],
    // detailImgUrl: [{ required: false, message: "请上传商品详情图", trigger: "change" }],
  };

  defaultVehicleList: any[] = [];
  checkPrice(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      priceRulesEl(value, callback);
    }
  }
  emptyToLine = emptyToLine;

  definitionPartsData: any = {
    partsUnit: "", // 单位
    sellingPrice: "", // 销售价格
    amount: "", // 账面金额
    servicePartsQty: "", // 数量
    partsCode: "" // 配件code
  };
  storeTypeList: any = [];
  twoLevelClassList: any = [];
  partsList: any[] = [];
  get orderId() {
    return this.$route.params.id;
  }
  created() {
    // this.setPBPX(52);
    this.init();
  }
  handleServiceTypeChange() {
    this.getTwoLevelClassList("");
  }
  serviceTypeList: any = [];
  twoLevelClassListCopy: any = [];
  handleSearchTwoClass(val) {
    if (val) {
      this.twoClassSearchValue = val;
      this.twoLevelClassList = this.twoLevelClassList.filter(item => {
        return item.serviceCategoryName.includes(val);
      });
    } else {
      this.twoLevelClassList = this.twoLevelClassListCopy;
    }
  }
  handleStoreTypeChange() {
    this.getPartsLists();
    this.orderFormData.servicePartsList = [];
  }

  // handleCarWashModesChange(){
  //   this.orderFormData.carWashMode
  // }
  handleAddParts() {
    if (!this.orderFormData.storeTypes || this.orderFormData.storeTypes.length < 1) {
      return this.$message.error("请先选择同步门店类型");
    }
    this.orderFormData.servicePartsList.push(Object.assign({}, this.definitionPartsData));
  }
  twoClassSearchValue: string = ""; // 品牌搜索keyword
  handleServiceClassChange() {
    this.getTwoLevelClassList("");
    this.orderFormData.serviceCategoryCode = "";
  }
  getTwoLevelClassList(val = "", callback?) {
    this.queryTwoLevelClassList({
      serviceCategoryName: val,
      serviceClass: "",
      pageSize: 999,
      pageNum: 1
    }).then(data => {
      this.twoLevelClassList = data.data.list;
      this.twoLevelClassListCopy = data.data.list.slice();
      callback && callback();
    });
  }
  async init() {
    this.getPartsLists();
    if (this.isAdd) {
      this.getTwoLevelClassList("");
    }
    this.getDictionaryList("store_type").then(data => {
      // console.log(data, "storeTypeList");
      this.storeTypeList = data;
    });
    this.getDictionaryList("service_class").then(data => {
      this.serviceTypeList = data;
    });

    if (!this.isAdd) {
      const serviceItemData = await this.getopserviceitem({
        serviceCode: this.$route.params.id
      });
      serviceItemData.data.servicePartsList.forEach(item => {
        if (
          !this.partsList.some(partsItem => {
            return partsItem.partsCode === item.partsCode;
          })
        ) {
          this.partsList.push({
            partsCode: item.partsCode,
            partsName: item.partsName
          });
        }
      });
      this.orderFormData = Object.assign(serviceItemData.data);
      this.defaultBannerList.push({url: this.orderFormData.imgUrl })
      this.defaultDetailList.push({url: this.orderFormData.detailImgUrl })
      this.getTwoLevelClassList("");
      this.defaultVehicleList = this.vehicleDataFormat(serviceItemData.data.vehicleSeries);
    }
  }
  vehicleDataFormat(data) {
    return data.map(item => ({
      lastValue: item.seriesName,
      name: item.brandName + " / " + item.seriesName,
      valueGroup: [item.brandName, item.seriesName]
    }));
  }
  submitForm(formName) {
    console.log(this.orderFormData, "this.orderFormData");

    if (this.orderFormData.serviceType === "1") {
      // 保存的时候如果是工时项目，清空零配件
      this.orderFormData.servicePartsList = [];
    }
    (this.$refs.serviceForm as any).validate(valid => {
      if (valid && this.checkAddData()) {
        if (this.isAdd) {
          this.addService();
        } else {
          this.editService();
        }
      } else {
        console.log("error submit!!");
      }
    });
  }
  addService() {
    this.btnStartLoading();
    this.createServiceItem(this.orderFormData)
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.go(-1);
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }
  editService() {
    this.btnStartLoading();
    console.log(JSON.stringify(this.orderFormData))
    this.updateServiceItem(this.orderFormData)
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.go(-1);
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }
  getPartsLists(name = "") {
    this.getPartsList({
      pageNum: 1,
      pageSize: 20,
      searchKey: name,
      storeTypeArray: this.orderFormData.storeTypes
    }).then(data => {
      this.partsList = data.data.list ? data.data.list : [];
    });
  }

  handleSwitchType(key) {
    this.orderFormData.orderTypeByImmediately = false;
    this.orderFormData.orderTypeByAppointment = false;
    if (key === "orderTypeByImmediately") {
      this.orderFormData.reservationArrivalTime = "";
    } else {
      this.orderFormData.leaveTime = "";
    }
    this.orderFormData[key] = true;
  }
  computedAmount(index) {
    let amount =
      (this.orderFormData.servicePartsList[index].servicePartsQty || 0) *
      this.orderFormData.servicePartsList[index].sellingPrice;
    this.orderFormData.servicePartsList[index].amount = amount.toFixed(2);
    this.computedTotalAmount();
  }
  computedTotalAmount() {
    const partsTotal = this.orderFormData.servicePartsList.reduce((preV, curV) => preV + Number(curV.amount), 0);
    if (Object.is(NaN, partsTotal)) {
      return this.$message.error(t("setting.enter-quantity-or-amount"));
    }
    console.log(partsTotal);
    this.orderFormData.costAmount = partsTotal.toFixed(2);
  }
  searchParts(val) {
    this.getPartsLists(val);
  }
  handleChooseParts(val, index, originData?) {
    if (!val) return;
    let findData = this.partsList.find(item => item.partsCode === val);
    this.getPartsDetail({
      partsCode: val
    }).then(data => {
      let partsData = Object.assign(
        {},
        {
          partsCode: val,
          partsUnit: data.data.partsUnit, // 单位
          sellingPrice: data.data.sellingPrice, // 销售价格
          amount: data.data.amount, // 账面金额
          servicePartsQty: data.data.servicePartsQty // 数量
        }
      );
      this.orderFormData.servicePartsList.splice(index, 1, partsData);
      this.computedAmount(index);
    });
  }
  handlePartsClear(index) {
    this.orderFormData.servicePartsList.splice(index, 1, Object.assign({}, this.definitionPartsData));
  }
  checkAddData(): boolean {
    if (this.orderFormData.serviceType === "2" && this.orderFormData.servicePartsList.length < 1) {
      this.$message.error(t("setting.add-least-one-component"));
      return false;
    }
    for (let index = 0; index < this.orderFormData.servicePartsList.length; index++) {
      const element = this.orderFormData.servicePartsList[index];
      if (!element.partsCode) {
        this.$message.error(t("setting.select-associated-parts"));
        return false;
      }
      if (!element.servicePartsQty) {
        this.$message.error(t("setting.fill-quantity-required"));
        return false;
      }
      const checkPartsQty = partsQuantityRules(element.servicePartsQty);
      if (!checkPartsQty.success) {
        this.$message.error(checkPartsQty.msg);
        return false;
      }
    }
    return true;
  }

  deleteItem(key, index) {
    console.log(key, index);
    this.orderFormData[key].splice(index, 1);
  }
}
